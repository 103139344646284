<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="managmentProduct.lstProducts"
            :loading="managmentProduct.loading"
            no-data-text="Não há produtos"
            no-results-text="Nenhum produto encontrado"
            locale="pt-BR"
            hide-default-footer
            disable-sort
            mobile-breakpoint="350"
            :items-per-page="managmentProduct.limit"
        >
            <template v-slot:loading>

            </template>
            <template v-slot:item="{ item: pr0 }">
                <tr>
                    <td class="text-left">{{ pr0.pr0produto }}</td>
                    <td class="text-left">{{ pr0.pr0desc | capitalize }}</td>
                    <td class="text-left">{{ pr0.co0nome | capitalize}}</td>
                    <td>
                        <v-icon small @click="find(pr0)" :color="parameters.secondaryColor">
                            mdi-pencil
                        </v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-row dense class="pt-3">
            <v-col cols="6" class="d-flex justify-start align-center">
                <span class="caption">Itens por página: </span>
                <v-select
                    v-model="limit"
                    :items="items"
                    filled
                    :color="parameters.secondaryColor"
                    @change="change"
                    hide-details
                    style="max-width: 95px"
                    class="pl-2 overline"
                    dense
                    single-line
                ></v-select>
            </v-col>
            <v-col cols="6" class="d-flex justify-end align-end">
                    <v-btn
                        :color="parameters.primaryColor"
                        elevation="0"
                        icon
                        @click="changePage(-1)"
                        :disabled="managmentProduct.page == 1"
                    ><v-icon>mdi-chevron-left</v-icon></v-btn>
                    <v-card
                        :color="parameters.secondaryColor"
                        class="d-flex justify-center align-center"
                        elevation="1"
                        dark
                        max-width="36"
                        height="36"
                        width="36"
                    >
                        <v-card
                            dark
                            color="transparent"
                            class="d-flex align-center"
                            height="29"
                            width="29"
                            elevation="0"
                        >
                            <div class="button flex-grow-1 text-center white--text">{{ managmentProduct.page }}</div>
                        </v-card>
                    </v-card>
                    <v-btn
                        :color="parameters.primaryColor"
                        elevation="0"
                        icon
                        :disabled="!managmentProduct.hasNext"
                        @click="changePage(1)"
                    ><v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        headers: [
            { text: 'Código', value: 'pr0produto' },
            { text: 'Produto', value: 'pr0desc' },
            { text: 'Coleção', value: 'co0nome' },
            { text: '', value: '' },
        ],
        items: [ 20, 50, 100 ],
        limit: 20
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        managmentProduct: {
            get() {
                return this.$store.state.managmentProduct;
            },
        },
    },
    methods:{
        find(pr0){
            this.$router.push(`/produto/editar/${pr0.pr0produto}`)
        },
        change(){
            this.$store.dispatch("managmentProduct/findList", 
            {
                search: this.managmentProduct.search,
                limit: this.limit
            });
        },
        changePage(value){
            this.$store.dispatch("managmentProduct/findList", 
            {
                search: this.managmentProduct.search,
                limit: this.managmentProduct.limit,
                page: this.managmentProduct.page+value
            });
        }
    }
};
</script>

<style>
</style>